export const chainsData = [
    {
        id: "1",
        name: "Juno Testnet",
        explorerLink: "https://testnet.juno.explorers.guru/transaction/",
    },
    {
        id: "2",
        name: "Juno Mainnet",
        explorerLink: "https://juno.explorers.guru/transaction/",
    },
    {
        id: "3",
        name: "Archway Testnet",
        explorerLink: "https://testnet.archway.explorers.guru/transaction/",
    },
    {
        id: "4",
        name: "Canto Mainnet",
        explorerLink: "https://tuber.build/tx/",
    },
    {
        id: "34",
        name: "Canto Testnet",
        explorerLink: "https://testnet-explorer.canto.neobase.one/tx/",
    },
    {
        id: "40",
        name: "Ethereum Goerli testnet",
        explorerLink: "https://goerli.etherscan.io/tx/",
    }
]