import { FinishSetupDestination } from '@/common/types/common.types'
import { pxToRem } from '@/common/utils'
import { UserDestination } from '@/proto/api_pb'
import { styled, Typography } from '@mui/material'
import DestinationRow from '../components/DestinationRow'

type DestinationViewProps = {
  allUserDestinations: UserDestination.AsObject[]
  notFinishedDestination?: FinishSetupDestination
  onOpenModal: () => void
  clearNotFinishedDestination: () => void
  deleteDestination: (id: string) => void
}

export default function DestinationView({
  allUserDestinations,
  notFinishedDestination,
  onOpenModal,
  clearNotFinishedDestination,
  deleteDestination,
}: DestinationViewProps) {
  return (
    <Container>
      {allUserDestinations?.length === 0 && (
        <Typography sx={{ color: '#FFFFFF7A', fontSize: '16px' }}>No Destinations yet</Typography>
      )}
      {notFinishedDestination?.confirmProposalId && (
        <DestinationRow
          destinationId={notFinishedDestination.confirmProposalId}
          destinationType={notFinishedDestination.type}
          label={notFinishedDestination.value}
          finishSetup={false}
          active={false}
          cleanDestinationData={clearNotFinishedDestination}
          handleOpenConfirmationModal={onOpenModal}
        />
      )}
      {allUserDestinations?.map((destination: UserDestination.AsObject, index: number) => {
        let destinationType =
          destination.destination?.email != ''
            ? 'EMAIL'
            : destination.destination?.telegram != ''
            ? 'TELEGRAM'
            : destination.destination?.webhook != ''
            ? 'WEBHOOK'
            : 'DISCORD'

        let label = Object.values(destination.destination || {}).find(value => value != '')

        return (
          <DestinationRow
            destinationId={destination.id}
            key={index}
            destinationType={destinationType}
            label={label}
            finishSetup={true}
            disableToggle={true}
            deleteDestination={deleteDestination}
          />
        )
      })}
    </Container>
  )
}

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: pxToRem(20),
  padding: `${pxToRem(20)} 0 ${pxToRem(50)}`,
  '@media (max-width: 768px)': {
    padding: `${pxToRem(10)} 0`,
  },
}))
